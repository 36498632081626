import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import tw, { css, styled, theme} from 'twin.macro'
import Accordion from './contact-accordion'
import map from 'lodash/map'
import kebabCase from 'lodash/kebabCase'
import Text from './text'
import { global } from '../styles/global'
import Select from './select'
import Button from './button'

const FAQ = ({ 
    selectBackground,
    style,
    subNavStyle,
    clipBackground,
    offices,
    buttonLabel,
    numQuestions,
  }) => {

  const [current, setCurrent] = useState(0)
  const [selectedPlan, setSelectedPlan] = useState(0)
  const [displayCount, setDisplayCount] = useState(numQuestions)

  const options = useMemo(() =>
    offices.map((link, i) => ({ label: link.title, value: i, isLink: false}))
  )
  const faqFilter = offices.filter((t, index) => index == current)
  const faqFilterMobile = offices.filter((t, index) => selectedPlan.value ? index == selectedPlan.value : index == 0)

  const displayedArticles = useMemo(() => {
    return faqFilter[0].faq?.slice(0, displayCount)
  }, [faqFilter, displayCount])

  const displayedArticlesMobile = useMemo(() => {
    return faqFilterMobile[0].faq?.slice(0, displayCount)
  }, [faqFilterMobile, displayCount])

  return (
    <div css={style}>
      <div css={[global`layout.container`, tw`hidden lg:(flex flex-col bg-grey-light)`]}> 
        <Accordion btnStyle={tw`py-12`} style={tw`xl:w-33/50`}>     
          {displayedArticles.map((link,i) => (                      
              <div key={link.title} id={kebabCase(link.title)} image={link.image}>    
              <div>            
                <Text content={link.descriptionNode} />
              </div>            
            </div>
          ))}
        </Accordion>
      </div>
      <div css={[global`layout.container`, tw`flex flex-col bg-grey-light lg:hidden`]}> 
      <Accordion btnStyle={tw`py-12`}>     
          {displayedArticlesMobile.map((link,i) => (                 
              <div key={link.title} id={kebabCase(link.title)} image={link.image}>    
              <div>            
                <Text content={link.descriptionNode} />
              </div>            
            </div>
          ))}
        </Accordion>
      </div>
      <div css={[tw`hidden lg:(block flex justify-center pt-16 pb-4) xl:w-33/50`, displayCount >= faqFilter[0].faq.length && tw`lg:hidden`]}>
        <Button
          label={buttonLabel}
          type="primary"
          size="sm"
          theme="yellow-navy"
          handleClick={() => {setDisplayCount(displayCount + 3)}}
          hideCaret
          caretDown
        />
      </div>
      <div css={[tw`flex justify-center pt-16 pb-4 lg:hidden`, displayCount >= faqFilterMobile[0].faq.length && tw`hidden`]}>
        <Button
          label={buttonLabel}
          type="primary"
          size="sm"
          theme="yellow-navy"
          handleClick={() => {setDisplayCount(displayCount + 3)}}
          hideCaret
          caretDown
        />
      </div>
    </div>
  )
}

FAQ.defaultProps = {
  numQuestions: 3,
}

FAQ.propTypes = {
  offices: PropTypes.arrayOf(PropTypes.object),
  buttonLabel: PropTypes.string,
  numQuestions: PropTypes.number,
}

export default FAQ