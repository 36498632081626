import PropTypes from 'prop-types'
import React from 'react'
import tw from 'twin.macro'
import { HTMLType, StyleType } from '../utils/prop-types'
import Button from './button'
import Heading from './heading'
import Link from './link'
import { global } from '../styles/global'
import Image from './image'
import Text from './text'

const ContactCard = ({ label, contactType, image, value, subtext, style, phoneStyle }) => {
  const getValueComponent = () => {
    switch (contactType) {
      case 'URL':
        return (
          <Button
            link={value}
            label={subtext}
            type="primary"
            size="base"
            theme="white-cyan"
            style={tw`bg-primary-50 text-primary-500`}
            hideCaret
          />
        )
      case 'Email':
        return (
          <Link to={`mailto:${value}`} style={global`base.hover-underline`()}>
            <Heading
              content={value}
              headingType="h6"
              style={[
                tw`font-normal xl:whitespace-pre-wrap`,
                value.length > 30 &&
                  tw`text-mobile-xss sm:text-sm md:text-lg xl:(whitespace-pre-wrap text-xl)`,
              ]}
            />
          </Link>
        )
      case 'Phone':
        return (
          <div>
            <Link to={`tel:+${value}`} style={global`base.hover-underline`()}>
              <Heading
                content={value}
                headingType="h6"
                style={[tw`font-normal text-primary-500`, phoneStyle]}
              />
            </Link>
            {subtext !== undefined && (
              <Text
              content={subtext}
              style={tw`font-normal text-primary-500 mt-4 text-mobile-xss sm:text-sm md:text-lg xl:text-xl`}
            />
            )}
          </div>
        )
      case 'Text':
      default:
        return <Heading content={value} headingType="h6" style={tw`font-normal text-primary-500`} />
    }
  }

  return (
    <>
      <div css={tw`flex flex-row items-start`}>
        <Image image={image} style={tw`w-18 h-18 mr-4 md:mr-8`} />
        <div css={[tw`flex flex-col items-start`, style]}>
          <Heading content={label} headingType="h4" style={tw`mb-4 font-medium text-primary-500`} />
          {getValueComponent()}
        </div>
      </div>
      <div
        css={tw`border-b-2 border-grey-80 xl:(border-b-0 border-r-2 px-1) last-of-type:border-none xxl:px-0`}
      ></div>
    </>
  )
}

ContactCard.defaultProps = {
  contactType: 'Text',
}

ContactCard.propTypes = {
  label: PropTypes.string,
  contactType: PropTypes.string,
  value: PropTypes.string,
  subtext: HTMLType,
  image: PropTypes.object,
  style: StyleType,
  phoneStyle: StyleType,
}

export default ContactCard
