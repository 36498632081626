import PropTypes from 'prop-types'
import React, { useState } from 'react'
import tw, { css } from 'twin.macro'
import { ReactComponent as Collapse } from '../images/icon-collapse.svg'
import { ReactComponent as Expand } from '../images/icon-expand.svg'
import { StyleType } from '../utils/prop-types'
import Heading from './heading'
import Image from './image'

const AccordionTab = ({ style, btnStyle, label, image, imgStyle, children }) => {
  const [isOpened, setOpen] = useState(false)
  
  return (
    <div
      css={[
        css`
          ${tw`py-8 border-b border-primary-500 border-opacity-10`}
          &:last-of-type > div > div {
            ${tw`lg:pb-0`}
          }
        `,
        style,
      ]}
    >
      <button
        type="button"
        div="true"
        css={[
          css`
            ${tw`flex flex-row justify-between items-center w-full py-8 -my-8 outline-none focus:outline-none`}
          `,
          btnStyle,
        ]}
        onClick={() => setOpen(!isOpened)}
      >{/* This component has a new css config, check if doesnt break any other part of the site */}
        <div css={tw`flex flex-row`}>
          <Image image={image} style={[tw`w-7 h-7`, imgStyle]} />        
          <Heading headingType="h5" content={label} 
            style={[
              tw`w-full text-left text-primary-500 font-normal col-start-2 md:(w-fit-content)`,
              isOpened && tw`text-opacity-100 font-semibold`,
            ]} />
        </div>
        <div css={tw`flex flex-col items-end w-25/10 `}>
          <Expand
            css={[
              tw`fill-current text-primary-500 transition duration-300 ease-in-out`,
              isOpened && tw`hidden`,
            ]}
          />
          <Collapse
            css={[
              tw`hidden`,
              isOpened && tw`block fill-current text-primary-500 transition duration-300 ease-in-out opacity-100 font-bold`,
            ]}/> 
        </div>               
      </button>
      <div
        css={[
          tw`max-h-0 overflow-hidden transition-all duration-300 ease-in-out`,
          isOpened && tw`max-h-accordion`,
        ]}
      >
        <div css={tw`py-8 lg:(pt-6 pb-4) flex justify-start`}>{children}</div>
      </div>
    </div>
  )
}

AccordionTab.propTypes = {
  style: StyleType,
  label: PropTypes.string,
  children: PropTypes.node,
  
}

const Accordion = ({ style, btnStyle, imgStyle, children }) => {
  return (
    <div css={[style]}>
      {React.Children.map(children || null, (child) => {
        return (
          <AccordionTab label={child.key} btnStyle={btnStyle} imgStyle={imgStyle} image={child.props.image}>
            <child.type {...child.props} key={child.key} />
          </AccordionTab>
        )
      })}
    </div>
  )
}

Accordion.propTypes = {
  style: StyleType,
  btnStyle: StyleType,  
  imgStyle: StyleType,
  children: PropTypes.node,
}

export default Accordion
