import { useEmblaCarousel } from 'embla-carousel/react'
import PropTypes from 'prop-types'
import React from 'react'
import tw, { css } from 'twin.macro'
import { global } from '../styles/global'
import { format } from '../utils/date'
import { StyleType } from '../utils/prop-types'
import { getNewsUrl } from '../utils/routing'
import Button from './button'
import Heading from './heading'
import Link from './link'
import Text from './text'

const ArticleCarousel = ({ cards, style }) => {
  const [viewportRef] = useEmblaCarousel({
    loop: false,
    speed: 5,
    align: 'start',
    containScroll: 'trimSnaps',
  })

  return (
    <div
      css={[
        global`layout.container`,
        css`
          ${tw`block overflow-visible`}
          &.is-draggable {
            ${tw`cursor-grab`}
          }
          &.is-dragging {
            ${tw`cursor-grabbing`}
          }
        `,
      ]}
      ref={viewportRef}
    >
      <div css={[tw`flex flex-row flex-nowrap`, style]}>
        {cards.map((card, index) => (
          <div
            key={index}
            css={tw`flex flex-col items-start justify-between flex-none w-full mx-container-mobile lg:(pr-8 ml-8 mr-0 w-116 border-r last:border-r-0) xl:w-140`}
          >
            <div css={tw`flex flex-col items-start`}>
              <Text
                style={tw`mb-2 text-secondary-910 text-xl font-normal`}
                content={card.typeOfArticle}
              />
              <Link to={getNewsUrl(card.slug, card.typeOfArticle)}>
                <Heading content={`${card.headline.substring(0, 50)}...`} headingType="h3" style={tw`font-bold`} />
              </Link>
              <Text
                style={tw`mt-6 text-purple text-xl font-normal`}
                content={card.contentNode.childMarkdownRemark.excerpt}
              />
            </div>
            <div css={[tw`mt-7 mb-5 font-bold text-primary-500 text-base`,]}>
              <time>{format(card.date, 'dd MMMM yyyy')}</time>
            </div>
            {/* <Button
              type="primary"
              size="sm"
              style={css`
                  ${tw`mt-7`}
                  span {
                    ${tw`ml-0`}
                  }
                `}
              theme="transparent-transparent"
              label="Read more" // TODO: Add into CMS
              link={getNewsUrl(card.slug, card.typeOfArticle)}
            /> */}
          </div>
        ))}
      </div>
    </div>
  )
}

ArticleCarousel.defaultProps = {
  cards: [],
}

ArticleCarousel.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.object),
  style: StyleType,
}

export default ArticleCarousel
