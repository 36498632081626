export function getNewsUrl(slug, typeOfArticle) {
  switch (typeOfArticle) { 
    case 'Telecomunications':
      return `/case-studies/telecomunications/${slug}`
    case 'e-wallets and super apps':
      return `/case-studies/e-wallets-and-super-apps/${slug}`
    case 'Perspectives':
      return `/news-insights/insights/${slug}`
    case 'In the news':
      return `/news-insights/insights/${slug}`
    default:
      return `/news/${slug}`
  }
}
